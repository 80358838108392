const camelCasify = (str = "", regex) => str.replace(regex, (g) => g[1].toUpperCase())

export const shouldExcludeProgram = (slug, color, exclusions = {}) => {
  const camelCasedSlug = camelCasify(slug, /-([a-zA-Z0-9])/g)
  const excludedProgram = exclusions[camelCasedSlug]

  if (!excludedProgram) return false

  return Object.keys(excludedProgram).length > 0
    ? !!excludedProgram[camelCasify(color, / ([a-zA-Z0-9])/g)]
    : excludedProgram
}

export const showDiscount = (config) => {
  const { promoType, onSale, isFinalSale } = config
  switch (promoType) {
    case "On sale products":
      if (onSale && !isFinalSale) {
        return true
      }
      return false
    case "Final sale products":
      if (isFinalSale) {
        return true
      }
      return false
    case "Final and on sale products":
      if (onSale || isFinalSale) {
        return true
      }
      return false
    case "Sitewide":
      return true
    default:
      return true
  }
}

const bundleColors = {
  black: true,
  charcoal: true,
  grey: true,
  lightGrey: true,
  lightNavy: true,
  navy: true,
}

export const bundleSlugsExclusions = {
  // ICON SUIT BUNDLES
  jetsetterLightBlueWindowpane: bundleColors,
  jetsetterIcebergBlue: bundleColors,
  jetsetterDustyRose: bundleColors,
  jetsetterStretchWoolSuitJacketSuitg00145: bundleColors,
  jetsetterStretchWoolSuitPantSuitg00146: bundleColors,
  jetsetterNavyPinkWindowpane: bundleColors,
  jetsetterBrownBluePlaid: bundleColors,
  jetsetterStretchWoolSuitLightNavy: bundleColors,
  jetsetterStretchWoolSuitLightGrey: bundleColors,
  jetsetterStretchWoolSuitBlackFa17: bundleColors,
  jetsetterStretchWoolSuitCharcoal: bundleColors,
  jetsetterStretchWoolSuitGrey: bundleColors,
  jetsetterStretchWoolSuitNavy: bundleColors,
  jetsetterStretchWoolSuitGreyBlueGlenPlaid: bundleColors,
  jetsetterStretchWoolSuitLightNavyWindowpane: bundleColors,
  jetsetterStretchWoolSuitHeatherGreyPinstripe: bundleColors,
  jetsetterStretchWoolSuitBlueMicrocheck: bundleColors,
  jetsetterStretchWoolSuitGreyCheck: bundleColors,
  jetsetterStretchWoolSuitSteelBlueTexture: bundleColors,
  jetsetterStretchWoolSuitBrownHoundstooth: bundleColors,
  jetsetterStretchWoolSuitLightBlueHoundstooth: bundleColors,
  jetsetterStretchWoolSuitBlueGlenPlaid: bundleColors,
  // EXTENDED SIZING ICON SUIT BUNDLES
  jetsetterStretchWoolSuitGreyExtendedSizing: bundleColors,
  jetsetterStretchWoolSuitLightNavyExtendedSizing: bundleColors,
  jetsetterStretchWoolSuitBlackExtendedSizing: bundleColors,
  jetsetterStretchWoolSuitNavyExtendedSizing: bundleColors,
  jetsetterStretchWoolSuitCharcoalExtendedSizing: bundleColors,
  jetsetterStretchWoolSuitLightBlueMicroExtendedSizing: bundleColors,
  jetsetterStretchWoolSuitSawyerBurgundyGlenPlaidExtendedSizing: bundleColors,
}
